// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAokLJOzwWZ2ibkavHVRjOje407Yi_clXA",
  authDomain: "dparlour-31b53.firebaseapp.com",
  databaseURL: "https://dparlour-31b53.firebaseio.com",
  projectId: "dparlour-31b53",
  storageBucket: "dparlour-31b53.appspot.com",
  messagingSenderId: "542120220543",
  appId: "1:542120220543:web:b66a316ecd902ffaf3ac4a",
  measurementId: "G-Q87GVPQPPZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const firestore = firebase.firestore();

export const createInviteRequest = async (requestDetails) => {

	const docRef = firestore.collection('InviteRequests').doc();

	try {

		await docRef.set({
			...requestDetails
		});

	} catch (error) {
		console.log("Error adding request invite");
		return null;
	}

	return docRef

}

export const getInviteRequest = async (id) => {
	
	const docRef = firestore.collection('InviteRequests').doc(id);

	const snapShot = await docRef.get();
	
	return snapShot
}

export {
	firebase,
	auth,
	firestore
}
import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Notifications, {notify} from 'react-notify-toast';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button/custom-button.component';
import { Colors } from '../utils/colors';
import Loader from 'react-loader-spinner';
import { createInviteRequest } from '../firebase/firebaseConfig';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const RequestInvite = () => {
	const[inputDetails, setInput] = useState({name: '', email: '', businessName: '', username: '', agreed: ''});

	const [loading, setLoading] = useState(false);

	const handleInput = (e) => {
		let { name, value } = e.target;
		setInput({...inputDetails, [name]: value });
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {

			await validateInputFields(inputDetails);
			
			setLoading(true);
		
			const response = await submitInviteRequest(inputDetails);
	
			if(response == null) return goToErrorPage();
	
			let isInviteRequestAdded = await validateInviteSent(response);
	
			if(isInviteRequestAdded) return gotToThankYouPage();
	
			setLoading(false);
	
			goToErrorPage();

		} catch(e) {

			showErrorToast(e);

		}

	
	} 

	const validateInputFields = ({ name, email, businessName, username }) => {
		if(name === '' || email === '' || businessName === '' || username === '') return Promise.reject('Please fill out all fields')

		if(businessName.length < 5) return Promise.reject('Please enter a valid business name') 

		return Promise.resolve();
	}

	const showErrorToast = (message) => {
		return notify.show(message, "error", 2000)
	}

	const submitInviteRequest = async (data) => {
		let docRefResponse = await createInviteRequest(data);
		return docRefResponse
	}

	const validateInviteSent = async (docRef) => {

		try {

			const snapShot = await docRef.get();

			if (snapShot.exists) {
				return true
			}

		}
		catch (error) {
			console.log("error");
		}

		console.log('Snapshot does not exist');

		return false

	}

	const gotToThankYouPage = () => {
		setLoading(false)
		navigate('thanks');
	}

	const goToErrorPage = () => {
		console.log("response is null");
		setLoading(false);
		navigate('error');
	}

	return ( 
		<Layout>
			<Wrapper>
				<Notifications />
				<Title>Get Invite.</Title>
				<Subtitle>Request your personal invite to DParlour</Subtitle>
				<Form onSubmit={handleSubmit}>
					<InputFieldContainer>
						<InputFieldLabel>Name</InputFieldLabel> 
						<InputField 
							type='text' 
							name='name' 
							value={inputDetails.name} 
							onChange={handleInput}
							placeholder='John Doe'
							pattern="[a-zA-Z\-']+\s[a-zA-Z]+"
							title="Please type yuh full name"
							/>
					</InputFieldContainer>

					<InputFieldContainer>
						<InputFieldLabel>Email</InputFieldLabel> 
						<InputField 
							type='email' 
							name='email' 
							value={inputDetails.email} 
							onChange={handleInput}
							placeholder='example@info.com'/>
					</InputFieldContainer>

					<InputFieldContainer>
						<InputFieldLabel>Business Name</InputFieldLabel> 
						<InputField 
							type='text' 
							name='businessName' 
							value={inputDetails.businessName} 
							onChange={handleInput}
							placeholder='Travel by Tonya'/>
					</InputFieldContainer>

					<InputFieldContainer>
						<InputFieldLabel>Username <SpanText>(This will be used for your payment link)</SpanText></InputFieldLabel> 
						<InputField 
							type='text' 
							name='username' 
							value={inputDetails.username} 
							onChange={handleInput}
							placeholder='Enter Username'
							pattern="^[a-z0-9]{4,}" title="Usernames must contain at least 4 letters and be all lowercased"
							/>
					</InputFieldContainer>

					<CheckBoxFieldContainer>
						<CheckBoxField 
							name='agreed' 
							value={inputDetails.agreed} 
							required
							onChange={(e) => {
								handleInput({
									target: {
										name: e.target.name,
										value: e.target.checked
									}
								})
							}}
							type='checkbox' />
						<CheckBoxLabel>By requesting an invite you agree to the <AccentText href="/terms-of-service" target="_blank">terms of use</AccentText> and <AccentText href="/privacy-policy" target="_blank">privacy policy.</AccentText></CheckBoxLabel> 
					</CheckBoxFieldContainer>

					<RequestInviteButton type='submit' background={Colors.primary} color={Colors.white} width='300px'>Request Invite </RequestInviteButton>
				<Spinner
						type="Circles"
						color="#00BFFF"
						height={100}
						width={100}
						timeout={0}
						visible={loading}
					/>
				</Form>
			</Wrapper>
		</Layout>
	 );
}
 
export default RequestInvite;



const Wrapper = styled.div`
max-width: 500px;
margin-left: auto;
margin-right: auto;
`;

const Title = styled.h1`
font-family: "Circular Std Book", Lato, sans-serif;
font-size: 20px;
color: ${Colors.primary};
margin-bottom: 0;
`;

const Subtitle = styled.h3`
margin-top: 20px;
font-family: "Circular Std Medium", Lato, sans-serif;
font-size: 16px;
font-weight: 400;
color: ${Colors.tertiary}
`;

const Form = styled.form`
position: relative;
`;

const InputFieldContainer = styled.div`
margin-top: 40px;
`;


const InputFieldLabel = styled.p`
display: inline-block;
font-family: "Circular Std Medium", Lato;
font-size: 18px;
color: ${Colors.parlor_grey};
margin-bottom: 0;
`;

const InputField = styled.input`
width: 100%;
margin-top: 8px;
box-sizing: border-box;
border-radius: 10px;
border-color: ${Colors.parlor_grey};
border-width: 1px;
height: 50px;
padding-left: 20px;
`;

const SpanText = styled.span`;
font-size: 14px;
opacity: 0.6;
`;

const CheckBoxFieldContainer = styled.div`
margin-top: 40px;
display: flex;

`;
const CheckBoxLabel = styled.h6`
display: inline-block;
font-family: "Circular Std Medium", Lato;
font-size: 18px;
color: ${Colors.parlor_grey};
margin-bottom: 0;
width: 450px;
margin-top: 0;
padding-top: 0;
font-weight: 400;
margin-left: 20px;
`;

const CheckBoxField = styled.input`
height: 18px;
`;

const AccentText = styled.a`
color: ${Colors.secondary};
`;

const RequestInviteButton = styled(CustomButton)`
display: block;
margin-top: 40px;
outline: none;
margin-left: auto;
margin-right: auto;
@media(min-width: 800px){
	width: 500px;
}
`;

const Spinner = styled(Loader)`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;